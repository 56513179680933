<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <box-regular :title="$t('operation.title')" value="121"></box-regular>
        </v-col>
      </v-row>
    </v-card>

    <v-form>
      <v-row
        class="mx-4 mt-4 d-inline-flex justify-space-between align-center"
        style="width: 100%"
      >
        <v-col cols="12" md="2">
            <v-select
              :items="areas"
              label="Area"
              v-model="location_areas"
              :item-text="`description`"
              :item-value="`id`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>
      </v-row>
    </v-form>

    <v-data-table
      class="custom-table ma-5 mt-10"
      :headers="headers"
      :items="summary.results"
      :server-items-length="summary.count"
      :row-per-page="limit"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageOptions: [],
      }"
      dense
      :hide-default-footer="summary.length < 5"
      @update:page="paginate"
    >
      <template v-slot:[`item.gtin`]="props">
        <router-link
          :to="{ name: 'operations-report', params: { id: props.item.gtin } }"
        >
          {{ props.item.gtin }}
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/dist/moment";
import i18n from "@/i18n";

export default {
  name: "operations",
  async mounted() {
    this.fetchLocationAreas();
},
  data() {
    return {
      limit: 10,
            currentPage: 0,

      username: "",
      query:null,
      filter:false,
      operations: [],
      menu1: false,
      location_areas:null,
      menu2: false,
      headers: [
        { text: i18n.t("label.gtin"), value: "gtin" },
        { text: i18n.t("label.description"), value: "description" },
        { text: i18n.t("label.quantity"), value: "quantity" },

      ],
    };
  },
  methods: {
    ...mapActions({
        fetchLocationAreas: "operations/fetchArea",
        fetchSummaryTable: "operations/fetchSummaryTable",

    }),

    handleChange() {
      this.createParams();
      this.fetchSummaryTable({"id":this.location_areas,"params":this.params});
    },
    formattedDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
    },
     paginate(val) {
      this.currentPage = val - 1;
      this.fetchSummaryTable({
          id: this.location_areas,
          params: {
          limit: this.limit,
          offset: this.currentPage * this.limit}
      });
    },
    createParams() {
      const params = {};
      params["limit"] = this.limit;
      params["offset"] = this.offset;
      this.params = params;
    },
  },
  computed: {
    ...mapGetters({
      areas: "operations/getAreas",
      summary: "operations/getSummary",

    }),
  },
};
</script>

<style></style>
