<template>
  <div v-if="itemstock">
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
        </v-col>
      </v-row>
    </v-card>
    <v-data-table
      class="custom-table ma-5 mt-16"
      :headers="headers"
      :items="itemstock"
      dense
      hide-default-footer    >
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  name: "operations",
  async mounted() {
    await this.fetchItem({itemType__gtin:this.$route.params.id});
    this.fetchItemStock(this.item[0])
  },
  data() {
    return {
      headers: [
        {
          text: i18n.t("label.id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: i18n.t("label.date"), value: "date" },
        { text: i18n.t("label.stage"), value: "area1.description" },
        { text: i18n.t("label.client"), value: "item.associated" },
        { text: i18n.t("label.AreaD"), value: "item.location_area_default.description" },

      ],
    };
  },
  methods: {
    ...mapActions({
      fetchItem: "operations/fetchItem",
      fetchItemStock: "operations/fetchItemStock",
    }),
  },
  computed: {
    ...mapGetters({
      item: "operations/getItem",
      itemstock: "operations/getItemStock",

    }),
  },
};
</script>

<style></style>
